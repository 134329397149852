import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistCombineReducers, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { apiReducer } from './features/api';
import { authReducer } from './features/auth';
import { rtkQueryToastMiddleware } from './features/toasts';
import { isBrowser } from './utils/isBrowser';

const rootReducer = persistCombineReducers(
  { key: 'spd-tracking', storage, whitelist: ['auth'] },
  {
    api: apiReducer,
    auth: authReducer
  }
);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = [rtkQueryToastMiddleware];

    if (process.env.NODE_ENV === `development` && isBrowser()) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const { logger } = require(`redux-logger`);
      middleware.push(logger);
    }
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] // redux-persist actions contains functions
      }
    }).concat(middleware);
  }
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
