// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ticket-new-tsx": () => import("./../../../src/pages/ticket/new.tsx" /* webpackChunkName: "component---src-pages-ticket-new-tsx" */),
  "component---src-pages-ticket-ticket-id-tsx": () => import("./../../../src/pages/ticket/[ticketId].tsx" /* webpackChunkName: "component---src-pages-ticket-ticket-id-tsx" */),
  "component---src-pages-tracking-email-verification-tsx": () => import("./../../../src/pages/tracking-email-verification.tsx" /* webpackChunkName: "component---src-pages-tracking-email-verification-tsx" */),
  "component---src-pages-tracking-tracking-id-tsx": () => import("./../../../src/pages/tracking/[trackingId].tsx" /* webpackChunkName: "component---src-pages-tracking-tracking-id-tsx" */)
}

