import React from 'react';
import { toast } from 'react-toastify';
import { CircleErrorIcon, CircleOkIcon } from '../../icons';

type ToastType = 'error' | 'success' | 'info';

type ToastArgs = {
  type?: ToastType;
  text?: string;
  title?: string;
};

export const showToast = (args: ToastArgs) => {
  toast[args.type || 'info'](<Toast {...args} />);
};

const Toast = ({ type, title, text }: ToastArgs) => (
  <div className="flex-1 flex flex-row items-starts">
    <div className="mr-2">{iconForType(type)}</div>
    <div className="space-y-1">
      {title && <p className="text-sm font-medium text-gray-900">{title}</p>}
      {text && <p className="text-sm text-gray-500">{text}</p>}
    </div>
  </div>
);

const iconForType = (type: ToastType | undefined) => {
  switch (type) {
    case 'error':
      return <CircleErrorIcon className="w-5 h-5 text-red-500" />;
    case 'success':
      return <CircleOkIcon className="w-5 h-5 text-green-500" />;
    default:
      return '';
  }
};
