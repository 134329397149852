import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_BASE_URL, TENANT } from '../costants';
import { RootState } from '../store';
import { ApiResponse } from './api/api';

type User = {
  created_at: string;
  details: {
    address: string;
    aggr_credits: number;
    city: string;
    color_primary: null;
    color_secondary: null;
    company_name: string;
    country: string;
    created_at: string;
    id: number;
    logo: string;
    logo_url: string;
    mkt_consent: number;
    phone: string;
    postcode: string;
    province: string;
    region: string;
    tax_code: string;
    type: string;
    updated_at: string;
    user_id: number;
    vat_num: string;
  };
  email: string;
  name: string;
  tenant: { code: string; name: string; created_at: string };
};

export type LoginResult = {
  user: User;
  access_token: string;
  message?: string;
  error?: Map<string, string>;
};

export type LoginDTO = { email: string; password: string; rememberMe: boolean };

const getUser = async ({ access_token }: { access_token: string }) =>
  (
    await axios.get<ApiResponse<User>>(`${API_BASE_URL}/spa/user`, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })
  ).data;

const login = createAsyncThunk<LoginResult, LoginDTO>('auth/login', async (params: LoginDTO) => {
  let access_token: string;
  try {
    const response = await axios.post(`${API_BASE_URL}/api/login`, params, { headers: { 'X-Tenant': TENANT } });
    access_token = response.data.access_token;
  } catch (ex) {
    return ex.response.data as LoginResult;
  }
  const userReponse = await getUser({ access_token });
  return {
    user: userReponse.data,
    access_token
  };
});

export const verifyTokenAndUpdateUser = createAsyncThunk('auth/verifyToken', async (params, thunkAPI) => {
  const access_token = tokenSelector(thunkAPI.getState() as RootState);
  if (!access_token) {
    throw new Error('No access token');
  }

  return getUser({ access_token });
});

const logout = createAsyncThunk('auth/logout', (params, thunkAPI) => {
  thunkAPI.dispatch(clearAuthSlice());
  return axios.post<{ access_token: string }>(`${API_BASE_URL}/api/logout`);
});

type AuthState = {
  user?: User;
  access_token?: string;
};

const authInitialState = {
  access_token: undefined,
  user: undefined,
  error: undefined,
  message: undefined
};

const slice = createSlice({
  name: 'auth',
  initialState: authInitialState as AuthState,
  reducers: {
    clearAuthSlice: () => authInitialState,
    setToken: (state, action: PayloadAction<string>) => {
      state.access_token = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => action.payload),
      builder.addCase(verifyTokenAndUpdateUser.rejected, () => authInitialState);
    builder.addCase(verifyTokenAndUpdateUser.fulfilled, (state, action) => {
      state.user = action.payload.data;
    });
  }
});

const authReducer = slice.reducer;
export const { clearAuthSlice, setToken } = slice.actions;

const authStateSelector = (s: RootState) => s.auth;
const userSelector = createSelector(authStateSelector, (auth) => auth.user);
const tokenSelector = createSelector(authStateSelector, (auth) => auth.access_token);
const hasCompletedSignupSelector = createSelector(userSelector, (user) => Boolean(user?.details));

export { logout, login, authReducer, userSelector, tokenSelector, hasCompletedSignupSelector };
