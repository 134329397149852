import { SerializedError } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { useEffect } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { API_BASE_URL, TENANT } from '../../costants';
import { RootState } from '../../store';
import { customLanguageDetector } from '../../strings';

export const CONTENT_TYPE_AUTO = 'auto';

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: async (headers, { getState }) => {
      const { auth } = getState() as RootState;
      if (auth?.access_token) {
        headers.set('authorization', `Bearer ${auth.access_token}`);
      }
      headers.set('Accept', 'application/json');
      if (!headers.has('Content-Type')) {
        headers.set('Content-Type', 'application/json');
      }
      if (headers.get('Content-Type') === CONTENT_TYPE_AUTO) {
        headers.delete('Content-Type');
      }
      headers.set('X-Tenant', TENANT);

      // Pass language of the browser to the server
      const acceptLanguage = customLanguageDetector();
      headers.set('Accept-Language', acceptLanguage);

      return headers;
    }
  }),
  endpoints: () => ({}),
  keepUnusedDataFor: 0
});

export const { middleware, reducer: apiReducer } = baseApi;

// AXIOS CONFIG
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

export type ApiResponse<T> = {
  data: T;
  message?: string;
  errors?: Map<string, string[]>;
};

export type ApiErrorResponse = {
  errors: Map<string, string[]>;
  message: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isApiErrorResponse = (data: any): data is ApiErrorResponse => data && 'errors' in data && 'message' in data;

const getBackendErrors = (error: FetchBaseQueryError | SerializedError | undefined) => {
  if (error && 'data' in error && isApiErrorResponse(error.data)) {
    return error.data;
  }

  return false;
};

/**
 * automatically show errors coming from `apiError` on the form
 * apiError is from [trigger, { *error* }]
 * setError is from { setError } = useForm()
 */
export const useSetApiErrorOnForm = <T>({
  apiError: postError,
  setError
}: {
  apiError: FetchBaseQueryError | SerializedError | undefined;
  setError: UseFormSetError<T>;
}) => {
  useEffect(() => {
    const e = getBackendErrors(postError);
    if (e) {
      Object.entries(e.errors).forEach(([field, [error]]) =>
        setError(field as keyof UseFormSetError<T>, { message: error })
      );
    }
  }, [postError, setError]);
};
