import { it } from 'date-fns/locale';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-GB';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { isBrowser } from '../utils/isBrowser';
import deStrings from './de.json';
import enStrings from './en.json';
import esStrings from './es.json';
import frStrings from './fr.json';
import itStrings from './it.json';

export const dateFNSMappedLocales = {
  it: it,
  en: en,
  de: de,
  fr: fr,
  es: es
};

/**
 * Custom language detector, the one provided by i18next doens't work here
 * It's just a check on window navigator language
 *
 */
export const customLanguageDetector = () => {
  if (isBrowser()) {
    const language = window.navigator.language;
    if (language.includes('it')) {
      return 'it';
    }
    if (language.includes('de')) {
      return 'de';
    }
    if (language.includes('fr')) {
      return 'fr';
    }
    if (language.includes('es')) {
      return 'es';
    }
    return 'en';
  }
  // If not in browser return 'it' as default
  return 'it';
};

export const setupLocalizations = () => {
  // This function is used also in the gatsby-ssr.js file so we need to check if window is undefined
  let language = 'it';
  language = customLanguageDetector();

  i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      initImmediate: false,

      resources: {
        it: {
          translation: itStrings
        },
        en: {
          translation: enStrings
        },
        de: {
          translation: deStrings
        },
        fr: {
          translation: frStrings
        },
        es: {
          translation: esStrings
        }
      },
      lng: language,
      fallbackLng: 'en',

      interpolation: {
        escapeValue: false
      }
    });
};
