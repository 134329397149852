import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { showToast } from './showToast';
import i18next from 'i18next';

export const rtkQueryToastMiddleware: Middleware = (/*api: MiddlewareAPI*/) => (next) => (action) => {
  const [p1, p2, p3] = action.type.split('/');

  if (p1 === 'api') {
    if (p3 === 'rejected') {
      if (isRejectedWithValue(action)) {
        let message = action.payload.data?.message;

        const key = `http_status_errors.${action.payload.status}`;
        if (i18next.exists(key)) {
          message = i18next.t(key);
        }

        showToast({ type: 'error', title: i18next.t('toast.error'), text: message });
      }
    }

    if (p3 === 'fulfilled' && p2 === 'executeMutation') {
      const message = action.payload.message;
      showToast({ type: 'success', title: i18next.t('toast.success'), text: message });
    }
  }

  return next(action);
};
