import React from 'react';
import { ToastContainer } from 'react-toastify';
import { CloseIcon } from '../../icons';

export const SpedireToastContainer = () => (
  <ToastContainer
    toastClassName={
      'flex flex-row items-center relative max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden mb-6'
    }
    closeButton={<CloseIcon className="w-3 h-3 text-gray-400 hover:text-gray-500 m-3" />}
    bodyClassName={'text-sm font-med block p-3 text-black flex-1'}
  />
);
